import { Component, OnInit, Input } from '@angular/core';
import { Merchant } from '../../models/merchant.model';
// @ts-ignore
import { FeedService } from 'src/app/services/feed.service';
import { NavController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-merchant-card-two',
  templateUrl: './merchant-card-two.component.html',
  styleUrls: ['./merchant-card-two.component.scss'],
})
// @ts-ignore
export class MerchantCardTwoComponent implements OnInit {

  constructor(
    private feed: FeedService,
    private toastController: ToastController,
    private authService: AuthService,
    private utils: UtilsService,
  ) { }

  @Input() merchant: Merchant;
  @Input() favourite: boolean;
  noFeauturedImg = '../../../assets/no_img1.png';

  ngOnInit() {
  }

  setFavourite() {
    if (!this.authService.isGuest) {
      this.feed.favouriteMerchant(this.merchant.id).subscribe(resp => {
        if (resp.code === 20) {
          this.favourite = true;
          this.toastController.create({
            message: resp.message[0],
            duration: 2000,
            color: 'success',
            position: 'top',
            cssClass: 'toast-alert',
            buttons: [
              {
                text: 'undo',
                icon: 'ios-undo',
                side: 'end',
                handler: () => {
                  this.setUnFavourite();
                },
              },
            ],
          })
          .then((toastEl) => toastEl.present());
        } else {
          this.utils.showErrorMessage('Cannot add Merchant to favorites');
        }
      });
    } else {
      this.utils.showErrorMessage('Guests cannot add Merchant to favorites. Please create an account.');
    }

  }

  setUnFavourite() {
    this.feed.unFavouriteMerchant(this.merchant.id).subscribe(resp => {
      if (resp.code === 20) {
        this.favourite = false;
        this.toastController.create({
          message: resp.message[0],
          duration: 2000,
          color: 'success',
          position: 'top',
          cssClass: 'toast-alert',
          buttons: [
            {
              text: 'undo',
              icon: 'ios-undo',
              side: 'end',
              handler: () => {
                this.setFavourite();
              },
            },
          ],
        })
        .then((toastEl) => toastEl.present());
      } else {
        this.utils.showErrorMessage('Cannot remove Merchant from favorites');
      }
    });
  }
}
