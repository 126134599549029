import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss'],
})
export class ChangePasswordPage implements OnInit, OnDestroy {
  private subs = new SubSink();
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';

  constructor(
    private loadingCtrl: LoadingController,
    private auth: AuthService,
    private utils: UtilsService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  async onSubmit(form: NgForm) {
    const { oldPassword, newPassword, confirmPassword } = form.value;
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
      translucent: true,
    });

    if (oldPassword === newPassword) {
      this.utils.showErrorMessage('Your new password cannot be the same as the old password.');
      this.dismiss();
    } else {
      loading.present();
      this.subs.sink = this.auth
      .changePassword(oldPassword, newPassword, confirmPassword)
      .subscribe(
        resp => {
          if (resp.type === 'success') {
            loading.dismiss();
            this.utils.showSuccessMessage(resp.message[0]);
            this.dismiss();
          } else {
            loading.dismiss();
            this.utils.showErrorMessage(resp.message[0]);
            this.dismiss();
          }
        },
        error => {
          loading.dismiss();
          console.error(error), this.utils.showServerError();
        }
      );
    }
  }

  // Dismisses the modal
  dismiss() {
    return this.modalController.dismiss({
      dismissed: true,
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
