/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable arrow-body-style */
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { OrderItem, OrderSideItem } from '../../models/order.model';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  @Input() item: OrderItem;
  sideItemMap: Map<string, OrderSideItem[]> = null;
  sides: [];
  qty: [];
  sidePrice: number;


  constructor() {}

  ngOnInit() {
    this.sideItemMap = this.groupBy(
      this.item.sides,
      item => item.side_group_name
    );
  }

  getItemSize() {
    let size = '';

    if (this.item.size === 'None') {
      size = 'Regular';
    } else if (this.item.size === 'N/A') {
      size = 'Regular';
    } else {
      size = this.item.size;
    }

    return size;
  }

  groupBy(
    list: OrderSideItem[],
    keyGetter: (item: OrderSideItem) => string
  ): Map<string, OrderSideItem[]> {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  shouldDisplayBadge() {
    if (this.item.size === 'None' || this.item.size === 'N/A') {
      return false;
    } else {
      return true;
    }
  }

  getPrice(sides, side) {
    sides.forEach(item => {
      if (item.takeout.name === side) {
        this.sidePrice = item.price;
      }
    });
  }


  displayPrice(price: number, qty: number): string {
    return `+ $${price * qty}`;
  }
}
