import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PolicyPagePage } from '../policy-page/policy-page.page';

@Component({
  selector: 'app-terms-and-policy',
  templateUrl: './terms-and-policy.component.html',
  styleUrls: ['./terms-and-policy.component.scss'],
})
export class TermsAndPolicyComponent implements OnInit {

  @Input() theme: string;
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  async showPolicyModal(type: string) {
    const modal = await this.modalController.create({
      component: PolicyPagePage,
      cssClass: 'policy-modal',
      componentProps: {
        type,
      }
    });
    return await modal.present();
  }

}
