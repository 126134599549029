import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import {
  LocalNotifications,
  ELocalNotificationTriggerUnit,
} from '@ionic-native/local-notifications/ngx';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(
    private socket: Socket,
    private localNotifs: LocalNotifications,
    private storage: Storage,
    private platform: Platform,
    private router: Router
  ) {}

  initializeSocket() {
    this.socket.on('connect', () => {
      console.log('We connected');
      this.reconnectMerchants();
    });

    this.localNotifs.on('click').subscribe(res => {
      this.router.navigateByUrl('/order-history');
    });

    this.socket.on('order-ready', data => {
      data = JSON.parse(data);
      console.log('socket resp ', data);
      this.platform.ready().then(() => {
        this.localNotifs.schedule({
          id: 1,
          title: 'Order Ready!',
          text: `Your order from ${data.merchant_name} is ready!`,
          trigger: { in: 1, unit: ELocalNotificationTriggerUnit.SECOND },
          foreground: true,
        });
      });
      this.removeSocketString(data.merchant_id.toString());
    });

    this.socket.on('order-cancelled', data => {
      data = JSON.parse(data);
      console.log('socket resp ', data);
      this.platform.ready().then(() => {
        this.localNotifs.schedule({
          id: 1,
          title: 'Order Cancellation',
          text: `Your order from ${data.merchant_name} has been cancelled.`,
          trigger: { in: 1, unit: ELocalNotificationTriggerUnit.SECOND },
          foreground: true,
        });
      });
      this.removeSocketString(data.merchant_id.toString());
    });
  }

  async storeNotifyeeString(merchantId: string, notifyeeString: string) {
    let socketStrings = await this.storage.get('socketStrings');
    if (!socketStrings) {
      socketStrings = {};
    }
    socketStrings[merchantId] = notifyeeString;
    await this.storage.set('socketStrings', socketStrings);
  }

  async reconnectMerchants() {
    const socketStrings = await this.storage.get('socketStrings');
    console.log('Socket Strings: ', socketStrings);

    if (socketStrings) {
      Object.keys(socketStrings).forEach(merchantId => {
        console.log('Joining merchant ', merchantId);
        this.socket.emit('join', socketStrings[merchantId]);
      });
    }
  }

  async removeSocketString(merchantId: string) {
    const socketStrings = await this.storage.get('socketStrings');
    if (socketStrings) {
      delete socketStrings.merchantId;
    }
    await this.storage.set('socketStrings', socketStrings);
  }
}
