import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  SideGroup,
  SelectedSide,
  Item,
  ChangeTotalEvent,
} from 'src/app/models/menu.model';
import { PriceUpdate } from 'src/app/models/order.model';

@Component({
  selector: 'side-group',
  templateUrl: './side-group.component.html',
  styleUrls: ['./side-group.component.scss'],
})
export class SideGroupComponent implements OnInit {
  @Input() sideGroup: SideGroup;
  @Output() changeSideEvent = new EventEmitter<SelectedSide[]>();
  @Output() changeTotalEvent = new EventEmitter<ChangeTotalEvent>();
  @Output() updatePriceEvent = new EventEmitter<PriceUpdate>();

  selection: SelectedSide[] = [];
  total = 0;
  isOpen = true;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.sideGroup.sides.forEach(item => {
      const {id, name} = item.takeout;
      const quantity = item.qty || 0;
      this.total += quantity;
      this.selection.push(new SelectedSide(id, quantity, name, item.price, this.sideGroup.title));
    });
  }

  toggleSelection() {
    this.isOpen = !this.isOpen;
  }

  emitData(data: SelectedSide[]) {
    this.changeSideEvent.emit(data);
  }

  emitTotal() {
    this.changeTotalEvent.emit({
      total: this.total,
      id: this.sideGroup.id,
    });
  }

  emitPriceUpdate(action: string, price: number) {
    this.updatePriceEvent.emit({
      action,
      price,
    });
  }

  increment(sideIndex: number) {
    if (this.total < this.sideGroup.max_qty) {
      this.selection[sideIndex].qty++;
      this.total++;
      this.emitData(this.selection);
      this.emitTotal();
      this.emitPriceUpdate('increment', this.selection[sideIndex].price);
    }
  }

  decrement(sideIndex: number) {
    if (this.selection[sideIndex].qty > 0) {
      this.selection[sideIndex].qty--;
      this.total--;
      this.emitData(this.selection);
      this.emitTotal();
      this.emitPriceUpdate('decrement', this.selection[sideIndex].price);
    }
  }

  // async handleIncrement(side, i) {
  //   if (side.takeout.sizes.length > 0) {
  //     const modal = await this.modalController.create({
  //       component: SizePickerPage,
  //       cssClass: 'size-picker-modal',
  //       componentProps: {
  //         itemSizes: side.takeout.sizes,
  //       }
  //     });

  //     return await modal.present();
  //   } else {
  //     this.increment(i);
  //   }
  // }
}
