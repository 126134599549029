import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit {
  public isWeb: boolean;

  constructor(private platform: Platform) { }

  ngOnInit() {
    this.isWeb = (this.platform.is('desktop') || this.platform.is('mobileweb'));
  }

}
