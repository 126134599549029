import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-delivery-provider',
  templateUrl: './delivery-provider.page.html',
  styleUrls: ['./delivery-provider.page.scss'],
})
export class DeliveryProviderPage implements OnInit {
  image = '../../../assets/icons/icon-192x192.png';

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    // fetch delivery merchants
  }

  handleClick() {
    // Code to map selected delivery provider to the order
    // console.log('clicked');

    this.dismiss();
  }

  dismiss() {
    return this.modalCtrl.dismiss({
      dismissed: true
    });
  }

}
