import { Component, Input, OnInit } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AlertController, ModalController } from '@ionic/angular';
import { SignUpPage } from 'src/app/sign-up/sign-up.page';
import { ReCaptchaService } from 'angular-recaptcha3';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.page.html',
  styleUrls: ['./login-form.page.scss'],
})
export class LoginFormPage implements OnInit {

  img = '../../../assets/login-background.jpg';

  @Input() loginData: any;
  phone = '';
  constructor(
    private auth: AuthService,
    private keyboard: Keyboard,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private recaptchaService: ReCaptchaService
  ) { }

  ngOnInit() {
    if (this.loginData) {
      this.populateField();
    }
  }

  /**
   * Populates the Login Field with the [phone number from the sign up page.
   */
  populateField() {
    this.phone = this.loginData.phone;
  }

  /**
   * Passes login form submission to auth service.
   */
  onSubmit(form: NgForm) {
    const {phone, password} = form.value;
    this.recaptchaService.execute({action: 'login'}).then(token => {
      // Backend verification method
      // console.log(token)
      this.sendTokenToBackend(token);
    });
    this.auth.login(phone, password)
    .then((resp) => {
      this.modalCtrl.dismiss();
    });
  }

  sendTokenToBackend(token) {
    this.auth.verifyRecaptcha(token).then(resp => {
      console.log(resp);
    });
  }

  /**
   * Presents an alert to collect the user's email to send them an email
   * with a link to change their password.
   */
  async forgotPassword() {
    const alert = await this.alertCtrl.create({
      header: 'Forgot Password',
      subHeader: 'Confirm email',
      message: 'Enter your email address',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'example@email.com'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: ({email}) => {
            this.auth.resetPassword(email);
          }
        }
      ]
    });

    await alert.present();
    // await alert.onWillDismiss()
  }

  /**
   * Allows user to submit the login form using the enter key on the keyboard
   */
  onEnter(event: any, form: NgForm) {
    if (event.keyCode === 13) {
      if (this.keyboard.isVisible) {
        console.log('keyboard visible');
        if (form.valid) {
          this.onSubmit(form);
        }
        this.keyboard.hide();
      } else {
        console.log('keyboard not visible');
      }
    }
  }

  async goToSignUp() {
    await this.dismiss();
    const modal = await this.modalCtrl.create({
      component: SignUpPage,
      cssClass: 'login-modal'
    });
    return await modal.present();
  }

  dismiss() {
    return this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

}
