import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeliveryAddress } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SubSink } from 'subsink';
import { AddressModalPage } from '../address-modal/address-modal.page';
import { MapPage } from '../map/map.page';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
})
export class AddressCardComponent implements OnInit {
  private subs = new SubSink();
  background = './background.png';
  constructor(
    private userService: UserService,
    private utils: UtilsService,
    private modalController: ModalController,
  ) { }

  @Input() address: DeliveryAddress;
  @Input() deleteHandler: (addrId: number) => void;
  @Input() updateHandler: (address) => void;

  ngOnInit() {
    this.userService.refreshNeeded.subscribe(() => {});
  }

  async addDeliveryAddress() {
    const modal = await this.modalController.create({
      component: MapPage,
      cssClass: 'address-modal'
    });

    return modal.present();
  }

}
