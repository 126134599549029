import { Component, OnInit, Input } from '@angular/core';
import { Item } from 'src/app/models/menu.model';

@Component({
  selector: 'app-suggested-item',
  templateUrl: './suggested-item.component.html',
  styleUrls: ['./suggested-item.component.scss'],
})
export class SuggestedItemComponent implements OnInit {
  @Input() item: Item;
  constructor() { }

  ngOnInit() {}

}
