import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { Merchant } from 'src/app/models/merchant.model';
import { SearchModalPage } from 'src/app/search-modal/search-modal.page';
import { FeedService } from 'src/app/services/feed.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-app-search-bar',
  templateUrl: './app-search-bar.component.html',
  styleUrls: ['./app-search-bar.component.scss'],
})
export class AppSearchBarComponent implements OnInit {

  @Input('mobileVisible') mobileVisible? : boolean = false;
  @Input('desktopVisible') desktopVisible? : boolean = false;
  @Input('isGuest') isGuest? : boolean = false;

  @Input('isLandingPage') isLandingPage?: boolean = false;
  @Input('isTabPage') isTabPage?: boolean = false;
  

  @ViewChild('searchbar') searchbar: IonSearchbar;
  @ViewChild('mobileSearchbar') mobileSearchbar: IonSearchbar;
  @ViewChild('favSearchbar') favSearchbar: IonSearchbar;
  @ViewChild('allSearchbar') allSearchbar: IonSearchbar;

  private find : string = '';
  public preSearchResult : Merchant[];
  public found: Merchant[];
  public merchants: Merchant[] = []; 
  public favouriteMerchants: Merchant[] = []; 


  isSearchOpen = false;

  constructor(
    private feed: FeedService,
    private modalController: ModalController,
    private util: UtilsService
  ) { }

  ngOnInit() {

    this.feed.getMerchants().subscribe((resp) => {
      this.merchants = resp.data.merchants as Merchant[];
    },
    (error) => this.util.showServerError()
    );
  }

  onChange(event) {
    this.find = event.target.value;
  }

  searchForMerchant(event) {
    this.feed.searchForMerchant(event.target.value)
    .subscribe( resp => {
      this.preSearchResult = resp.data.merchants;
      console.log(this.preSearchResult)
    });
  }

  async buttonSearch() {
    if (this.find.trim() === '') {
      this.found = null;
      return;
    }
    this.found = this.merchants.filter((merchant) => {
      return merchant.name.toLowerCase().includes(this.find.toLowerCase());
    });

    if (this.found.length === 0) {
      this.found = null;
    }
    const modal = await this.modalController.create({
      component: SearchModalPage,
      cssClass: 'search-modal',
      componentProps: {
        merchants: this.found
      }
    });
    return await modal.present();
  }


  async search(event) {
    if (event && event.key === 'Enter') {
      if (this.find.trim() === '') {
        this.found = null;
        return;
      }
      this.found = this.merchants.filter((merchant) => {
        return merchant.name.toLowerCase().includes(this.find.toLowerCase());
      });

      if (this.found.length === 0) {
        this.found = null;
      }
      const modal = await this.modalController.create({
        component: SearchModalPage,
        cssClass: 'search-modal',
        componentProps: {
          merchants: this.found
        }
      });
      return await modal.present();
    }
  }

  toggleSearch() {
    if (this.isSearchOpen) {
      this.isSearchOpen = false;
    } else {
      this.isSearchOpen = true;
    }
  }

  onClear(event) {
    this.toggleSearch();
  }

  clearSearch() {
    this.preSearchResult = null;
    this.find = null;
    this.searchbar.value = '';
    this.mobileSearchbar.value = '';

    if (this.favSearchbar) {
      this.favSearchbar.value = '';
    }

    if (this.allSearchbar) {
      this.allSearchbar.value = '';
    }

  }

}
