import { Component, OnInit, Input } from '@angular/core';
import {
  ModalController,
  NavParams,
  ToastController,
  AlertController,
} from '@ionic/angular';
import { CartService } from '../../services/cart.service';
import { CartPage } from 'src/app/cart/cart.page';
import { CartItem } from '../../models/cart.model';
import {
  SideGroup,
  SelectedSide,
  ChangeTotalEvent,
} from 'src/app/models/menu.model';

@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.page.html',
  styleUrls: ['./order-modal.page.scss'],
})
export class OrderModalPage implements OnInit {
  @Input() id: number;
  @Input() merchant_id: number;
  @Input() name: string;
  @Input() photo: string;
  @Input() description: string;
  @Input() price: number;
  @Input() options: SideGroup[];
  @Input() isOpen: boolean;

  // quantity will be 1 by default
  qty: number = 1;
  notes = '';
  selectedSides: SelectedSide[] = [];
  requiredAmount = 0;
  totalSelectedOptions = 0;
  totalSelectedRequiredOptions = 0;

  private totalBySide = {};

  constructor(
    private modalController: ModalController,
    private cartService: CartService,
    private toastController: ToastController,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    if (this.options) {
      // Combine the total of all the max_qtys that are for required groups
      this.requiredAmount = this.getRequiredAmount();
    }

    if (!this.isOpen) {
      this.showClosedAlert();
    }
  }

  /**
   * Dismisses the order modal
   */
  dismiss() {
    this.modalController.dismiss(false);
  }

  /**
   * Returns the total number of required side selections that need to be made
   * for the required sideGroups
   */
  getRequiredAmount(): number {
    const ttl = this.options.reduce((total, current) => {
      if (current.is_required) {
        this.totalBySide[current.id] = 0;
        return total + current.max_qty;
      } else {
        return total + 0;
      }
    }, 0);
    // console.log(ttl);
    return ttl;
  }

  /**
   * Increments the quantity of the takeout in question
   */
  increment() {
    this.qty++;
  }

  /**
   * Decreases the quantity of the takeout in question
   */
  decrement() {
    this.qty--;
    // prevent qty from ever being less than one
    if (this.qty < 1) {
      this.qty = 1;
    }
  }

  /**
   * Keeps track of the number of required sides that have been selected
   * in order to optionally disable the add to cart button
   * @param ev ChangeTotalEvent
   */
  updateOptionTotal(ev: ChangeTotalEvent) {
    this.totalBySide[ev.id] = ev.total;
    const sides = Object.keys(this.totalBySide);

    // getting the total number of selected events
    this.totalSelectedOptions = sides.reduce((total, sideId) => {
      return this.totalBySide[sideId] + total;
    }, 0);

    this.totalSelectedRequiredOptions = sides.reduce((total, sideId) => {
      // getting the total number of required selected events.
      const sideGroup = this.options.find((s) => s.id.toString() === sideId);
      if (sideGroup && sideGroup.is_required) {
        return total + this.totalBySide[sideId];
      } else {
        return total + 0;
      }
    }, 0);
    console.log(
      'update total called. total options: ',
      this.totalSelectedOptions
    );
    console.log(
      'update total called. total required selected options: ',
      this.totalSelectedRequiredOptions
    );
    console.log('Required amount: ', this.requiredAmount);
  }

  updateOptions($event: SelectedSide[]) {
    console.log('updated options ', $event);
    $event.forEach((el) => {
      const tempSide = this.selectedSides.find(
        (side: SelectedSide) => side.name === el.name
      );
      if (el.qty > 0 && !tempSide) {
        // adding a selected side
        this.selectedSides.push(el);
      } else if (el.qty > 0 && tempSide) {
        // updating a selected side
        tempSide.qty = el.qty;
      } else {
        // removeing a selected side
        this.selectedSides = this.selectedSides.filter(
          (side: SelectedSide) => side.name !== el.name
        );
      }
    });
    // console.log('selected sides: ', this.selectedSides);
  }

  addToCart() {
    // let options = '';
    // if (this.selectedSides.length) {
    //   options = 'Options:';
    //   this.selectedSides.forEach(({ qty, name }: SelectedSide) => {
    //     options += `\n${qty} ${name}`;
    //   });
    //   if (this.notes.length) {
    //     options += '\n\n' + `Notes:\n ${this.notes}`;
    //   }
    // } else {
    //   options = this.notes;
    // }
    // tslint:disable-next-line: variable-name
    const cart_item: CartItem = {
      id: this.id,
      merchant_id: this.merchant_id,
      name: this.name,
      photo: this.photo,
      description: this.description,
      price: this.price,
      qty: this.qty,
      notes: this.notes,
      size: '',
      sides: this.selectedSides
    };

    this.cartService.addToCart(cart_item);

    this.modalController.dismiss(true);
    this.toastController
      .create({
        message: `${this.qty} ${
          this.qty > 1 || this.qty === 0 ? 'items' : 'item'
        } added to cart.`,
        position: 'top',
        duration: 2000,
        color: 'dark',
        buttons: [
          {
            text: 'Go to cart',
            icon: 'arrow-forward',
            side: 'end',
            handler: () => {
              this.modalController
                .create({
                  component: CartPage,
                  id: 'cartModal',
                })
                .then((modalEl) => modalEl.present());
            },
          },
        ],
      })
      .then((toastEl) => toastEl.present());
  }

  async showClosedAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Closed',
      message:
        'Please note that orders cannot currently be made to this merchant because they are closed',
      backdropDismiss: true,
      buttons: ['Okay'],
    });
    await alert.present();
  }
}
