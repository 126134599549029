import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.page.html',
  styleUrls: ['./search-modal.page.scss'],
})
export class SearchModalPage implements OnInit {
  @Input() merchants;

  constructor(private modalCtrl: ModalController, private navController: NavController, private router: Router) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  async onClick(merchant) {
    await this.modalCtrl.dismiss({
      dismissed: true
    });
    // this.navController.navigateForward(['/home/merchant/', merchant.id]);
    this.router.navigateByUrl(`merchants/${merchant.id}`);
  }
}


