import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { AlertController, IonSlides, LoadingController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { LoginFormPage } from '../auth/login-form/login-form.page';
import { GuestUserService } from '../services/guest-user.service';
import { VerificationModalPage } from '../components/verification-modal/verification-modal.page';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss'],
})
export class SignUpPage implements OnInit {

  img = '../../../assets/login-background.jpg';

  @Input() signUpDetails: any;
  firstname = '';
  lastname = '';
  phone = '';
  email = '';
  password = '';
  confirm_password = '';

  constructor(
    private auth: AuthService,
    private keyboard: Keyboard,
    private loadingCtrl: LoadingController,
    private router: Router,
    private utils: UtilsService,
    private modalCtrl: ModalController,
    private guestUserService: GuestUserService,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    if (this.signUpDetails) {
      this.populateFields();
    }
  }

  populateFields() {
    const nameSplit = this.signUpDetails.name.split(' ');
    this.firstname = nameSplit[0];
    this.lastname = nameSplit[1];
    this.phone = this.signUpDetails.phone;
    this.email = this.signUpDetails.email;
  }

  async onSubmit(form: NgForm) {
    if (form.valid) {
      const {firstname, lastname, phone, email, password, confirm_password} = form.value;
      const loading = await this.loadingCtrl.create({
        message: 'Please wait...',
        translucent: true
      });
      loading.present();

      this.auth.signUp(firstname, lastname, phone, email, password, confirm_password)
      .subscribe(resp => {
        form.resetForm();
        // this.dismiss();
        loading.dismiss();
        this.utils.showSuccessMessage(resp.message[0]);
        if (resp.code === 21) {
          this.verifyEmail(resp.data.user.id);
          this.auth.setRespUserId(resp.data.user.id);
        }
      },
      err => {
        loading.dismiss();
        this.utils.showErrorMessage('We cannot process your request', 'Server Down', 5000);
      });

    }
  }

  onEnter(event: any, form: NgForm) {
    if (event.keyCode === 13) {
      if (this.keyboard.isVisible) {
        if (form.valid) {
          this.onSubmit(form);
        }
        this.keyboard.hide();
      }
    }
  }

  dismiss() {
    return this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  async goToLogIn() {
    await this.dismiss();
    const modal = await this.modalCtrl.create({
      component: LoginFormPage,
      cssClass: 'login-modal'
    });
    return await modal.present();
  }

  /**
   * Presents an alert to collect the user's verification code to verify their email.
   */
  async verifyEmail(userId: number) {
    const modal = await this.modalCtrl.create({
      component: VerificationModalPage,
      cssClass: 'verification-modal',
      backdropDismiss: false,
      componentProps: {
        requestNewCode: (id) => {  this.auth.requestNewCode(id); },
        verifyEmail: (id, code) => { this.auth.verifyEmail(id, code); },
        userId
      }
    });

    return modal.present();

    // DEPRECATED
    // const alert = await this.alertCtrl.create({
    //   header: 'Please Verify Email',
    //   subHeader: 'Check your email for code.',
    //   message: 'Please enter the code received to verify your email address.',
    //   inputs: [
    //     {
    //       name: 'code',
    //       type: 'text',
    //       placeholder: '000000'
    //     }
    //   ],
    //   buttons: [
    //     {
    //       text: 'Submit',
    //       handler: ({code}) => {
    //         this.auth.verifyEmail(userId, code);
    //       }
    //     },
    //     {
    //       text: 'Cancel',
    //       role: 'cancel',
    //     },
    //     {
    //       text: 'Request new code',
    //       handler: () => {
    //         this.auth.requestNewCode(this.auth.getRespUserId());
    //       }
    //     },
    //   ]
    // });

    // alert.onDidDismiss()
    // .then(resp => {
    //   this.goToLogIn();
    // });

    // await alert.present();
    // await alert.onWillDismiss()
  }

}
