import { Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { DeliveryAddress, MapAddress, BillingAddress} from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.page.html',
  styleUrls: ['./billing-address.page.scss'],
})
export class BillingAddressPage implements OnInit {
  line1: string;
  line2: string;
  city: string;
  parish: string;
  country: string;
  postalCode: string;
  phoneNumber: string;

  data: BillingAddress = {line1: '', line2: '', city: '', stateOrParish: '', country: '', postalCode: '', phoneNumber: ''};

  @Input() address: BillingAddress;
  constructor(
    private modalCtrl: ModalController,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.populateFields();
    this.data = this.address;
  }

  populateFields() {
    this.line1 = this.address.line1;
    this.line2 = this.address.line2;
    this.city = this.address.city;
    this.parish = this.address.stateOrParish;
    this.country = this.address.country;
    this.postalCode = this.address.postalCode;
    this.phoneNumber = this.address.phoneNumber;
  }

  dismiss() {
    return this.modalCtrl.dismiss({
      dismissed: true,
      returnedAddress: this.data
    });
  }

  onSave(f: NgForm) {
    const {line1, line2, city, parish, country, postalCode, phoneNumber} = f.value;
    this.data.line1 = line1;
    this.data.line2 = line2;
    this.data.city = city;
    this.data.stateOrParish = parish;
    this.data.country = country;
    this.data.postalCode = postalCode;
    this.data.phoneNumber = phoneNumber;
    this.dismiss();
  }

}
