import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment } from './../../environments/environment';
import { GeocodingResponse } from '../models/geocoding.model';
import { DeliveryAddress } from '../models/user.model';
// declare const google: any;


@Injectable({
  providedIn: 'root'
})
export class MapService {
  apiKey = environment.apiKey;
  private address: DeliveryAddress;
  private longAddress: any[] = [];

  constructor(
    private http: HttpClient,
  ) { }

  getAddress(latLng: google.maps.LatLng): Observable<GeocodingResponse> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat()},${latLng.lng()}&key=${this.apiKey}`;

    return this.http.get<GeocodingResponse>(url);
  }

  searchAddress(searchTerm: string): Observable<GeocodingResponse> {
    const urlEscapedAddress = encodeURI(searchTerm);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${urlEscapedAddress}&components=country:JM&key=${this.apiKey}`;

    return this.http.get<GeocodingResponse>(url);
  }

  getSelectedAddress() {
    return this.address;
  }

  getLongAddress() {
    return this.longAddress;
  }

  setAddress(address: DeliveryAddress) {
    this.address = address;
  }

  setLongAddress(address: any) {
    this.longAddress = address;
  }
}
