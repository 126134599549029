import { Injectable } from '@angular/core';
import { DeliveryAddress, PaymentCard } from 'src/app/models/user.model';
import { AuthService } from './auth.service';
import { Observable, Subject } from 'rxjs';
import { tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/takeout.model';
import { NgForm } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class GuestUserService {
  private deliveryAddress: DeliveryAddress;
  private cardDetails: PaymentCard;
  private contactDetails: any;

  constructor() { }

  setDeliveryAddress(address: DeliveryAddress) {
    this.deliveryAddress = address;
  }

  getDeliveryAddress() {
    return this.deliveryAddress;
  }

  setContactDetailsForm(data: any) {
    this.contactDetails = data;
  }

  getContactDetailsForm() {
    return this.contactDetails;
  }

  setCardDetails(cardInfo: PaymentCard) {
    this.cardDetails = cardInfo;
  }

  getCardDetails() {
    return this.cardDetails;
  }
}
