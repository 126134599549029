import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { CartPage } from 'src/app/cart/cart.page';
import { CartItem } from 'src/app/models/cart.model';
import { SelectedSide } from 'src/app/models/menu.model';
import { Order, OrderStatusUpdate } from 'src/app/models/order.model';
import { ApiResponse } from 'src/app/models/takeout.model';
import { CartService } from 'src/app/services/cart.service';
import { FeedService } from 'src/app/services/feed.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.page.html',
  styleUrls: ['./order-details.page.scss'],
})
export class OrderDetailsPage implements OnInit {
  @Input() order: Order;
  @Input() total: number;
  @Input() userId: number;
  // @Input() confirmOrderCancel: (order: Order) => void;
  deliveryAddress: string;
  instructions: string;
  fetchLatest = false;
  displayRefundStatus = false;
  refundStatus: string;

  private subs = new SubSink();

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private utils: UtilsService,
    private userService: UserService,
    private feed: FeedService,
    private cartService: CartService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.formatDeliveryAddress();
    this.getRefundStatus();
  }

  formatDeliveryAddress() {
    if (
      this.order.delivery_method.toLowerCase() === 'delivery' &&
      this.order.delivery_address !== 'True' &&
      this.order.delivery_address
    ) {
      const address = this.order.delivery_address.split(/\r?\n/);
      // eslint-disable-next-line max-len
      this.deliveryAddress = address[1].trim().slice(7) + ', ' + address[2].trim().slice(7) + ', ' + address[3].trim().slice(7);
      this.instructions = address[address.length - 1];
    }
  }

  async confirmOrderCancel(order: Order) {
    const alert = await this.alertCtrl.create({
      header: 'Important',
      message:
        // eslint-disable-next-line max-len
        'Are you sure you want to cancel this order?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel Order',
          handler: () => {
            this.cancelOrder(order);
          },
        },
        {
          text: 'Close',
          role: 'cancel',
        }
      ],
    });
    await alert.present();
  }

  async presentLoading(message) {
    const loading = await this.loadingCtrl.create({
      message,
      translucent: true,
    });
    await loading.present();
  }

  cancelOrder(order: Order) {
    this.presentLoading('Canceling Order...');
    const orderStatusUpdate: OrderStatusUpdate = {
      userId: this.userId,
      orderId: order.id,
      status: 4,
      name: order.contact_name,
      email: order.contact_email,
    };

    this.subs.sink = this.userService.cancelOrder(orderStatusUpdate).subscribe((resp: ApiResponse) => {
      this.loadingCtrl.dismiss();
      if (resp.code === 20) {
        this.utils.showSuccessMessage('Your Order was successfully cancelled.');
        this.fetchLatest = true;
        this.dismiss();
      } else {
        this.utils.showErrorMessage('We are unable to cancel your order.');
      }
    });
  }

  reorderItem(order: Order) {
    this.presentLoading('Adding to cart...');
    this.subs.sink = this.feed.getMerchant(order.merchant_id).subscribe(resp => {
      this.cartService.setMerchant(resp.data.merchant);
    });

    const orderItems = [];

    order.items.forEach(item => {
      const orderItem = {
        id: item.takeout_id,
        qty: item.quantity,
        size: item.size,
        notes: item.notes,
        quantity: item.quantity,
        sides: [],
      };

      // tslint:disable-next-line: no-string-literal
      const sidesArr = item['sides'];
      const sidesList = [];

      // tslint:disable-next-line: no-string-literal
      item['sides'].forEach(side => {
        sidesList.push(side.selected_side);
      });

      if (sidesList.length > 0) {
        item.takeout.options.forEach(op => {
          let sides = [];
          // eslint-disable-next-line @typescript-eslint/dot-notation
          sides = op['sides'];
          let qty = 0;

          sides.forEach((side, index) => {
            sidesArr.forEach(sideArrItem => {
              if (sideArrItem.selected_side === side.takeout.name) {
                qty = sideArrItem.quantity;
              }
            });

            if (sidesList.includes(side.takeout.name, index)) {
              orderItem.sides.push({ id: side.takeout_id, qty });
            }
          });
        });
      }
      orderItems.push(orderItem);
    });

    orderItems.forEach(orderItem => {
      this.subs.sink = this.feed
        .getTakeoutItem(order.merchant_id, orderItem.id)
        .subscribe(resp => {
          const takeout = resp.data.takeout;
          let price = 0;
          const selectedSides: SelectedSide[] = [];

          if (orderItem.size === 'None' || orderItem.size === 'N/A') {
            price = takeout.price;
          } else {
            takeout.sizes.forEach(size => {
              if (size.name === orderItem.size) {
                price = size.price;
              }
            });
          }

          if (takeout.options) {
            takeout.options.forEach(option => {
              option.sides.forEach(side => {
                orderItem.sides.forEach(orderSide => {
                  if (side.takeout_id === orderSide.id) {
                    // price += side.price * orderSide.qty;
                    const selSide = {
                      id: side.takeout_id,
                      qty: orderSide.qty,
                      name: side.takeout.name,
                      price: side.price,
                      sideGroupTitle: option.title,
                    };
                    selectedSides.push(selSide);
                  }
                });
              });
            });
          }

          const cartitem: CartItem = {
            id: takeout.id,
            merchant_id: takeout.merchant_id,
            name: takeout.name,
            photo: takeout.photo,
            description: takeout.description,
            price,
            qty: orderItem.quantity,
            notes: orderItem.notes,
            size: orderItem.size,
            sides: selectedSides,
          };

          this.cartService.addToCart(cartitem);
        });
    });

    setTimeout(() => {
      this.loadingCtrl.dismiss();
      this.openCart();
    }, 2000);
  }

  getRefundStatus() {
    if (this.order.payment) {
      if (this.order.payment.status === 'Refunded') {
        this.displayRefundStatus = true;
        this.refundStatus = this.order.payment.status;
      } else {
        this.displayRefundStatus = false;
        this.refundStatus = '';
      }
    }
  }

  safeMaskedNumber(cardNumber: string){
    const last4 = cardNumber.substring(cardNumber.length - 4);
    const mask = cardNumber.substring(0, cardNumber.length - 4).replace(/\d/g, '*');

    return mask+last4;
  }

  async openCart() {
    const modal = await this.modalController.create({
      component: CartPage,
      id: 'cartModal',
    });
    return await modal.present();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      data: {
        fetchLatest: this.fetchLatest
      }
    });
  }
}
