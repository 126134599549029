import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.page.html',
  styleUrls: ['./update-user.page.scss'],
})
export class UpdateUserPage implements OnInit, OnDestroy {
  private subs = new SubSink();
  firstname = '';
  lastname = '';
  email = '';
  phone = '';
  userId: number;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private loadingCtrl: LoadingController,
    private utils: UtilsService,
    private modalController: ModalController,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    this.populateFields();
    this.userId = this.authService.currentUser.id;
  }

  populateFields() {
    this.firstname = this.authService.currentUser.firstname;
    this.lastname = this.authService.currentUser.lastname;
    this.email = this.authService.currentUser.email;
    this.phone = this.authService.currentUser.phone;
  }

  async onSubmit(form: NgForm) {
    const { firstname, lastname, email, phone} = form.value;
    const userInfo = {firstname, lastname, email, phone};

    const currentFirstname = this.authService.currentUser.firstname;
    const currentLastname = this.authService.currentUser.lastname;
    const currentEmail = this.authService.currentUser.email;
    const currentPhone = this.authService.currentUser.phone;

    // If current email is the same as the one from the form we reset userInfo.email to an empty string
    // this prevents us from getting the value must be unique error
    if (currentEmail === email) {
      userInfo.email = '';
    }

    // If current phone is the same as the one from the form we reset userInfo.phone to an empty string
    // this prevents us from getting the value must be unique error
    if (currentPhone === phone) {
      userInfo.phone = '';
    }

    if (currentFirstname === firstname && currentLastname === lastname && currentEmail === email && currentPhone === phone) {
      this.utils.showNeutralMessage('No changes were detected.'); // Display response message
      this.dismiss(); // Dismisses the modal
    } else {
      this.presentLoading();
      this.subs.sink = this.userService
      .updateUserInfo(this.userId, userInfo)
      .subscribe(
        resp => {
          if (resp.code === 20) {
            this.authService.updateCurrentUserInfo(resp.data.user);
            this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
            this.dismiss(); // Dismisses the modal
            this.utils.showSuccessMessage(resp.message[0]); // Display response message
            // If the email sent was different from the user's current email
            if (currentEmail !== email) {
              this.verifyEmail(); // We prompt the user to verify new email
            }
          } else {
            this.utils.showErrorMessage(resp.message[0]);
            this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
          }
        },
        error => {
          this.loadingCtrl.dismiss(); // Dismiss the loading indicator modal
          this.utils.showErrorMessage('Could not update user');
        }
      );
    }
  }

  /**
   * Presents an alert to collect the user's verification code to verify their email.
   */
  async verifyEmail() {
    const alert = await this.alertCtrl.create({
      header: 'Verify new email',
      subHeader: 'Check your email for code.',
      message: 'Please enter the code received to verify your email address.',
      inputs: [
        {
          name: 'code',
          type: 'text',
          placeholder: '000000'
        }
      ],
      buttons: [
        {
          text: 'Submit',
          handler: ({code}) => {
            this.authService.verifyEmail(this.userId, code);
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Request new code',
          handler: () => {
            this.authService.requestNewCode(this.userId);
          }
        },
      ]
    });

    await alert.present();
  }

  async presentLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
      translucent: true,
    });
    loading.present();
  }

  // Dismisses the modal
  dismiss() {
    return this.modalController.dismiss({
      dismissed: true,
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
