import { Injectable,  } from '@angular/core';
import { Merchant } from '../models/merchant.model';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../models/takeout.model';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class MerchantDetailsService {
  private _merchant: Merchant;
  private url = environment.APIEndpoint;

  constructor(
   private http: HttpClient,
   private auth: AuthService
  ) { }

  public getMerchant() {
    return this._merchant;
  }

  public setMerchant(merchant: Merchant) {
    this._merchant = merchant;
  }

  public fetchMerchantDetails(merchantId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.url +
      `/merchants/${merchantId}`, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      }
    });
  }

  public fetchMerchantPaymentMethods(merchantId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.url +
      `/merchants/${merchantId}/payment_methods`, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      }
    });
  }
}
