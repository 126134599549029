import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-verification-modal',
  templateUrl: './verification-modal.page.html',
  styleUrls: ['./verification-modal.page.scss'],
})
export class VerificationModalPage implements OnInit {

  @Input() requestNewCode;
  @Input() verifyEmail;
  @Input() userId;
  code = '';
  isDisabled = true;
  attempts = 0;
  constructor(
    private modalController: ModalController,
  ) {}

  ngOnInit() {
  }

  async verifyUserEmail() {
    this.verifyEmail(this.userId, this.code);
  }

  async getNewCode() {
    if (this.attempts === 3) {
      this.dismiss();
    } else {
      this.requestNewCode(this.userId);
    }
    this.attempts += 1;
  }

  updateDisabled() {
    if (this.code !== '') {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  dismiss() {
    return this.modalController.dismiss({
      dismissed: true,
    });
  }

}
