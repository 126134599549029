import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './landing/landing.module#LandingPageModule'},
  { path: 'merchants', loadChildren: './tab1/tab1.module#Tab1PageModule' },
  { path: 'cart', loadChildren: './cart/cart.module#CartPageModule' },
  { path: 'checkout', loadChildren: './cart/pre-checkout/pre-checkout.module#PreCheckoutPageModule' },
  { path: 'auth', loadChildren: './auth/auth.module#AuthPageModule' },
  { path: 'order-history', loadChildren: './order-history/order-history.module#OrderHistoryPageModule' },
  { path: 'login-form', loadChildren: './auth/login-form/login-form.module#LoginFormPageModule' },
  { path: 'sign-up', loadChildren: './sign-up/sign-up.module#SignUpPageModule' },
  { path: 'account-settings', loadChildren: './account-settings/account-settings.module#AccountSettingsPageModule' },
  { path: 'address-modal', loadChildren: './components/address-modal/address-modal.module#AddressModalPageModule' },
  { path: 'map', loadChildren: './components/map/map.module#MapPageModule' },
  { path: 'delivery-provider', loadChildren: './components/delivery-provider/delivery-provider.module#DeliveryProviderPageModule' },
  { path: 'billing-address', loadChildren: './components/billing-address/billing-address.module#BillingAddressPageModule' },
  { path: 'change-password', loadChildren: './components/change-password/change-password.module#ChangePasswordPageModule' },
  { path: 'update-user', loadChildren: './components/update-user/update-user.module#UpdateUserPageModule' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
  { path: 'suggested-items', loadChildren: './components/suggested-items/suggested-items.module#SuggestedItemsPageModule' },
  { path: 'payment', loadChildren: './components/payment/payment.module#PaymentPageModule' },
];
@NgModule({
  imports: [
    // Once the Nginx 404 error has been resolved, set useHash to false
    RouterModule.forRoot(routes, {useHash: true , preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
