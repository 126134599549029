import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ApiResponse } from '../models/takeout.model';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Transaction, WalletPurchase, WalletReset } from '../models/partner.model';

@Injectable({
  providedIn: 'root'
})
export class TakeoutPartnerService {
  private url = environment.APIEndpoint;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  public getPartners(): Promise<ApiResponse> {
    return this.http.get<ApiResponse>(this.url + `/partners/codes`, {
      headers: {
        Authorization: 'Bearer ' + this.auth.token
      }
    }).toPromise();
  }

  public makeWalletPayment(walletPurchase: WalletPurchase): Promise<ApiResponse> {
    const { pin, amount, id } = walletPurchase;
    return this.http.post<ApiResponse>(this.url + `/wallets/make_payment`, {
      pin,
      amount,
      id
    }, {
      headers: {
        Authorization: 'Bearer ' + this.auth.token
      }
    }).toPromise();
  }

  public requestNewPin(walletReset: WalletReset): Promise<ApiResponse> {
    const { email, id } = walletReset;
    return this.http.post<ApiResponse>(this.url + `/wallets/reset_pin`, {
      email,
      id
    }, {
      headers: {
        Authorization: 'Bearer ' + this.auth.token
      }
    }).toPromise();
  }
}
