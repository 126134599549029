import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LoginFormPage } from './login-form.page';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { environment } from 'src/environments/environment';


const routes: Routes = [
  {
    path: '',
    component: LoginFormPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: environment.siteKey,
      },
      normal: {
        sitekey: environment.siteKey,
      },
      language: 'en'
  })
  ],
  declarations: [LoginFormPage]
})
export class LoginFormPageModule {}
