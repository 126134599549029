import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { SharedComponentsModule } from './components/shared-components.module';
import { environment } from './../environments/environment';
import { CartPageModule } from './cart/cart.module';
import { LoginFormPageModule } from './auth/login-form/login-form.module';
import { AddressModalPageModule } from './components/address-modal/address-modal.module';
import { MapPageModule } from './components/map/map.module';
import { DeliveryProviderPageModule } from './components/delivery-provider/delivery-provider.module';
import { BillingAddressPageModule } from './components/billing-address/billing-address.module';
import { OrderModalPageModule } from './tab1/order-modal/order-modal.module';
import { PreCheckoutPageModule } from './cart/pre-checkout/pre-checkout.module';
import { SearchModalPageModule } from './search-modal/search-modal.module';
import { SignUpPageModule } from './sign-up/sign-up.module';
import { ChangePasswordPageModule } from './components/change-password/change-password.module';
import { UpdateUserPageModule } from './components/update-user/update-user.module';
import { SuggestedItemsPageModule } from './components/suggested-items/suggested-items.module';
import { PaymentPageModule } from './components/payment/payment.module';
import { OrderDetailsPageModule } from './order-history/order-details/order-details.module';
import { VerificationModalPageModule } from './components/verification-modal/verification-modal.module';
import { PolicyPagePageModule } from './components/policy-page/policy-page.module';



const config: SocketIoConfig = { url: environment.APIEndpoint, options: {} };

@NgModule({
  declarations: [AppComponent, ],
  imports: [
    SearchModalPageModule,
    CartPageModule,
    LoginFormPageModule,
    AddressModalPageModule,
    MapPageModule,
    DeliveryProviderPageModule,
    BillingAddressPageModule,
    PaymentPageModule,
    VerificationModalPageModule,
    ChangePasswordPageModule,
    SuggestedItemsPageModule,
    UpdateUserPageModule,
    SignUpPageModule,
    OrderModalPageModule,
    PreCheckoutPageModule,
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    SocketIoModule.forRoot(config),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    MatStepperModule,
    BrowserAnimationsModule,
    MatIconModule,
    OrderDetailsPageModule,
    PolicyPagePageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Keyboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LocalNotifications,
    Geolocation
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
