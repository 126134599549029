import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Socket } from 'ngx-socket-io';
import { CartItem } from './../models/cart.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from './../models/takeout.model';
import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';
import { SocketService } from './socket.service';
import { Merchant } from '../models/merchant.model';
import { SelectedSide } from '../models/menu.model';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private url = environment.APIEndpoint;
  private _merchant: Merchant = null;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private socket: Socket,
    private socketService: SocketService
  ) {}

  get merchant() {
    return this._merchant;
  }

  setMerchant(merchant: Merchant) {
    this._merchant = merchant;
  }

  /**
   * Returns the total amount of items in the cart
   */

  getQuantity() {
    let quantity = 0;
    const cart = JSON.parse(localStorage.getItem('cart'));

    cart.forEach(item => {
      quantity += item.qty;
    });

    return quantity;
  }

  /**
   * Returns the total cost of items in the cart
   */

  getTotal() {
    let cost = 0;
    const cart = JSON.parse(localStorage.getItem('cart'));

    cart.forEach(item => {
      cost += item.qty * item.price;
    });

    return cost;
  }

  /**
   * Returns the cart Items
   */
  getItems() {
    const cart = JSON.parse(localStorage.getItem('cart'));
    return cart;
  }

  submitOrder(
    name: string,
    num: string,
    email: string,
    payment_method: string,
    delivery_method: string,
    new_delivery_address: string,
    payment_id?: number,
    status?: string,
    refNo? :string,
    created?: string,
    updated?:string,
    payment_card?:string,
    card_type?:string
  ): Observable<ApiResponse> {
    const order = {};
    const cart: CartItem[] = JSON.parse(localStorage.getItem('cart'));
    const order_items = cart.map(cartItem => ({
      takeout_id: cartItem.id,
      quantity: cartItem.qty,
      notes: cartItem.notes,
      size: cartItem.size,
      price: cartItem.price, // side price should not be added to this
      sides: cartItem.sides.map(s => ({
        quantity: s.qty,
        selected_side: s.name,
        price: s.price,
        side_group_name: s.sideGroupTitle,
      })),
    }));

    // build the order
    const notifyee_string = `tk_${this.auth.currentUser.id}_${this.merchant.id}`;
    
    if(payment_id){
      Object.assign(order, {
        user_id: this.auth.currentUser.id,
        contact_name: name,
        contact_email: email,
        contact_number: num,
        items: order_items,
        payment_method,
        delivery_method,
        new_delivery_address,
        notifyee: notifyee_string,
        payment_id: payment_id,
        card_pay_status:status,
        refNo,
        created,
        updated,
        payment_card,
        card_type,
      });
    }
    else{
      Object.assign(order, {
        user_id: this.auth.currentUser.id,
        contact_name: name,
        contact_email: email,
        contact_number: num,
        items: order_items,
        payment_method,
        delivery_method,
        new_delivery_address,
        notifyee: notifyee_string,
      });
    }
    
    this.socket.emit('join', notifyee_string);
    this.socketService.storeNotifyeeString(
      `${this.merchant.id}`,
      notifyee_string
    );

    return this.http.post<ApiResponse>(
      `${this.url}/merchants/${this.merchant.id}/orders-v2`,
      order,
      {
        headers: {
          Authorization: 'bearer ' + this.auth.token,
        },
      }
    );
  }

  getPaymentMethods(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.url}/feed/payment_methods`, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      },
    });
  }

  getDeliveryMethods(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.url}/feed/delivery_methods`, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      },
    });
  }

  getMerchantDeliveryMethods(merchantId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      `${this.url}/merchants/${merchantId}/delivery_methods`,
      {
        headers: {
          Authorization: 'bearer ' + this.auth.token,
        },
      }
    );
  }

  addToCart(item: CartItem) {
    let found = false;
    const cart = JSON.parse(localStorage.getItem('cart'));

    cart.forEach(cItem => {
      if (
        cItem.id === item.id &&
        cItem.notes === item.notes &&
        cItem.size === item.size &&
        this.sidesAreSame(cItem.sides, item.sides)
      ) {
        cItem.qty += item.qty;
        found = true;
      }
    });

    if (!found) {
      cart.push(item);
    }
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  updateCartItem(cartItem: CartItem, index:number )
  {
    const cart = JSON.parse(localStorage.getItem('cart')) as CartItem[];
    cart.splice(index,1);
    cart.splice(index,0,cartItem)
    console.log(cart)
    localStorage.setItem('cart', JSON.stringify(cart));
  }
  /**
   * Updates the quantity in the cart, of the passed item.
   * @param item
   * @param index
   */
  updateQty(item: CartItem, index: number) {
    const cart = JSON.parse(localStorage.getItem('cart'));
    cart[index].qty = item.qty;
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  /**
   * Removes an item from the cart.
   * @param item;
   */
  removeFromCart(item: CartItem) {
    let cart = JSON.parse(localStorage.getItem('cart'));
    cart = cart.filter(el => el.id !== item.id);

    if (cart.length === 0) {
      this._merchant = null;
    }
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  /**
   * Compares the sides selected for the incomming cart item, against an item
   * already in the cart.
   *
   * @param arr1
   * @param arr2
   * @returns Boolean
   */
   private sidesAreSame(arr1: SelectedSide[], arr2: SelectedSide[]) {
    if (arr1.length === 0 && arr2.length === 0) {
      return true;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }

    let same = true;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < arr1.length; i++) {
      console.log(arr1[i].toString(), arr2[i].toString());
      if (arr1[i].toString() !== arr2[i].toString()) {
        same = false;
        break;
      }
    }
    return same;
  }

  /**
   * Resets the cart and related info.
   */
  clearCart() {
    this._merchant = null;
    localStorage.setItem('cart', JSON.stringify([]));
  }
}
