import { Component, OnInit, OnDestroy, DoCheck, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { CartService } from '../services/cart.service';
import { PreCheckoutPage } from './pre-checkout/pre-checkout.page';
import { CartItem } from './../models/cart.model';
import { SubSink } from 'subsink';
import { UtilsService } from '../services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoginFormPage } from 'src/app/auth/login-form/login-form.page';
import { Location } from '@angular/common';
import { FeedService } from '../services/feed.service';
import { ApiResponse } from '../models/takeout.model';
import { MerchantDetailsService } from '../services/merchant-details.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss']
})
export class CartPage implements OnInit, DoCheck, OnDestroy {
  items: CartItem[] = [];
  total = 0;
  private subs = new SubSink();
  cartCount = 0;
  merchantName: string;
  merchantId: number;

  @Input() private updateCardItemIsOrigin: boolean = false;

  constructor(
    private auth: AuthService,
    private modalCtrl: ModalController,
    private navController: NavController,
    private alertCtrl: AlertController,
    private cartService: CartService,
    private util: UtilsService,
    private router: Router,
    private location: Location,
    private feedService: FeedService,
    private merchantDetailsService: MerchantDetailsService
  ) {
  }

  ngOnInit() {
    this.items = this.cartService.getItems();
    this.cartCount = this.cartService.getQuantity();
    this.total = this.cartService.getTotal();
    if (this.items.length > 0) {
      this.feedService.getMerchant(this.items[0].merchant_id)
      .subscribe(res => {
        this.merchantName = res.data.merchant.name;
        this.merchantId = res.data.merchant.id;
      });
    }
  }

  ngDoCheck() {
    this.items = this.cartService.getItems();
    this.cartCount = this.cartService.getQuantity();
    this.total = this.cartService.getTotal();

    if (localStorage.getItem('cartPageOrigin') === 'order-page') {
      this.updateCardItemIsOrigin = true;
      localStorage.removeItem('cartPageOrigin');
    }
  }

  editItem(index: number) {
    const cartItem = this.items[index];
    // Get & Set the merchant 
    this.feedService.getMerchant(cartItem.item.merchant_id)
      .subscribe((res) => {
        const merchant = res.data.merchant;
        this.merchantDetailsService.setMerchant(merchant);
        this.router.navigate([`/merchants/${cartItem.merchant_id}/${cartItem.id}`], {
          state: { cartItem, cartItemIndex: index, item: cartItem.item, isOpen: true, edit: true },
        });
      });
  }

  remove(index: number) {
    const item: CartItem[] = this.items.splice(index, 1);
    this.cartService.removeFromCart(item[0]);
  }

  async checkout() {
    this.router.navigateByUrl(`checkout`);
  }

  increment(index: number) {
    const item: CartItem = this.items[index];
    item.qty++;
    this.cartService.updateQty(item, index);
  }

  decrement(index: number) {
    const item: CartItem = this.items[index];
    item.qty--;
    if (item.qty < 1) {
      item.qty = 1;
    }
    this.cartService.updateQty(item, index);
  }

  dismiss() {
    console.log(this.updateCardItemIsOrigin)
    if (this.updateCardItemIsOrigin)
      this.router.navigateByUrl('/home')

    this.location.back();
  }

  isGuest(): boolean {
    return this.auth.isGuest;
  }

  isAuthenticated() {
    return this.auth.authState.value;
  }

  async login() {
    const modal = await this.modalCtrl.create({
      component: LoginFormPage,
      cssClass: 'login-modal'
    });
    return await modal.present();
  }

  logout() {
    this.auth.logout();
    this.cartService.clearCart();
    this.router.navigateByUrl('/home');
  }

  async confirmDelete(index: number) {
    const alert = await this.alertCtrl.create({
      header: 'Delete Item',
      message: 'Are you sure you want to remove this from your cart?',
      backdropDismiss: true,
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            this.remove(index);
            await alert.dismiss();
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => console.log('Canceled the delete')
        }
      ]
    });
    await alert.present();
  }

  goToMerchant() {
    this.router.navigateByUrl(`/merchants/${this.merchantId}`);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
