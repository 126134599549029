import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef, AfterViewInit} from '@angular/core';
import { DeliveryAddress, MapAddress } from 'src/app/models/user.model';
import { google } from '@google/maps';
import {MapService} from 'src/app/services/map.service';
import { LoadingController, ModalController } from '@ionic/angular';
import { AddressModalPage } from '../address-modal/address-modal.page';
import { Geolocation } from '@ionic-native/geolocation/ngx';
// import { UtilsService } from 'src/app/services/utils.service';
declare const google: any;


@Component({
  selector: 'app-map',
  templateUrl: './map.page.html',
  styleUrls: ['./map.page.scss'],
})
export class MapPage implements OnInit, AfterViewInit {
  lat = 18.0179;
  lng = -76.8099;

  @Input() addressDetails: DeliveryAddress;
  @Input() isDelivery: boolean;
  @Output() changeState = new EventEmitter<MapAddress>();
  @ViewChild('map') mapElement: ElementRef;
  map: google.maps.Map;
  latitude: number;
  longitude: number;
  address: string;
  marker: google.maps.Marker;
  isMapLoaded = false;
  guestDeliveryAddress: DeliveryAddress;
  returnedAddress: DeliveryAddress;

  t0: any;
  t1: any;


  constructor(
    private mapService: MapService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private geolocation: Geolocation,
    // private utils: UtilsService,
    ) { }

  ngOnInit() {
    this.startLoadingMap();
  }

  ngAfterViewInit() {
  }

  startLoadingMap() {
    this.t0 = performance.now();
    if (!this.addressDetails) {
      this.presentLoading();
      this.geolocation.getCurrentPosition({enableHighAccuracy: true}).then(resp => {
        const {latitude: lat, longitude: lng} = resp.coords;
        const latLng: google.maps.LatLng = new google.maps.LatLng(lat, lng);
        console.log(latLng)
        this.initMap(latLng);
      }).catch(e => {
        console.error(e);
        this.initMap(null);
      });
    } else {
      // this.presentLoading();
      const {latitude: lat, longitude: lng} = this.addressDetails;
      const latLng: google.maps.LatLng = new google.maps.LatLng(lat, lng);
      this.initMap(latLng);
    }
  }

  findMe() {
    this.presentLoading();
    this.geolocation.getCurrentPosition({enableHighAccuracy: true}).then(resp => {
      const {latitude: lat, longitude: lng} = resp.coords;
      const latLng: google.maps.LatLng = new google.maps.LatLng(lat, lng);
      this.initMap(latLng);
    }).catch(e => {
      console.error(e);
      this.initMap(null);
    });
  }

  async presentLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Loading map...',
      translucent: true,
      backdropDismiss: true,
    });
    loading.present();
  }

  async toAddressDetails() {
    const deliveryAddress: MapAddress = {
      latitude: this.latitude,
      longitude: this.longitude,
      address: this.address
    };

    this.mapService.setAddress(deliveryAddress);

    const modal = await this.modalCtrl.create({
      component: AddressModalPage,
      cssClass: 'address-modal-2'
    });

    modal.onDidDismiss()
    .then((resp) => {
      // Here we get the Guest Address from the Address Modal
      if (resp.data !== undefined) {
        this.returnedAddress = resp.data.returnedAddress;
        this.guestDeliveryAddress = resp.data.guestDeliveryAddress;
        this.dismiss();
      } else {
        this.dismiss();
      }
    });

    return await modal.present();
  }

  async updateAddress() {
    const deliveryAddress: MapAddress = {
      latitude: this.latitude,
      longitude: this.longitude,
      address: this.address
    };

    this.mapService.setAddress(deliveryAddress);

    const modal = await this.modalCtrl.create({
      component: AddressModalPage,
      cssClass: 'address-modal-2',
      componentProps: {
        addressDetails: this.addressDetails,
      }
    });

    modal.onDidDismiss()
    .then((resp) => {
      // Here we dismiss the map modal after the address form modal has been closed
      this.dismiss();
    });

    return await modal.present();
  }

  initMap(centre: google.maps.LatLng | null) {
    const JAMAICA_BOUNDS = {
      north: 18.529974,
      east: -76.205228,
      south: 17.682852,
      west: -78.440945
    };

    const restrictions = {
      latLngBounds: JAMAICA_BOUNDS,
      strictBounds: false
    };

    const mapOptions: google.maps.MapOptions = {
      center: centre ? centre :  new google.maps.LatLng(18.0179, -76.8099),
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_LEFT,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      restriction: this.isDelivery ? restrictions : '',
      minZoom: 10,
      zoom: 14
    };


    // register click event listener
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    if (centre) {
      this.addMarker(centre);
    }
    this.map.addListener('tilesloaded', () => {

      this.t1 = performance.now();
      console.log('Loading Map took ' + (this.t1 - this.t0) + ' milliseconds.');

      this.loadingCtrl.dismiss();
      this.isMapLoaded = true;
      google.maps.event.clearListeners(this.map, 'tilesloaded');
    });
    this.map.addListener('click', e => {
      this.addMarker(e.latLng);
    });
  }

  setLatLong(latLng: google.maps.LatLng) {
    this.latitude = latLng.lat();
    this.longitude = latLng.lng();
    this.getAddress(latLng);
  }

  getAddress(latLng: google.maps.LatLng) {
    this.mapService.getAddress(latLng).subscribe(resp => {
      this.mapService.setLongAddress(resp.results[0].address_components);
      this.address = resp.results[0].formatted_address;
    });
  }

  addMarker(latLng: google.maps.LatLng) {
    if (this.marker) {
      this.marker.setMap(null);
    }
    this.marker = new google.maps.Marker({
      position: latLng,
      animation: google.maps.Animation.DROP,
      draggable: true,
      map: this.map
    });
    this.setLatLong(latLng);

    this.marker.addListener('dragend', (event) => {
      this.setLatLong(event.latLng);
    });
  }

  searchAddress(searchTerm: string) {
    this.mapService.searchAddress(searchTerm).subscribe(
      resp => {
        // if (resp.results.length === 0 && this.isDelivery) {
        //   console.log(resp)
        //   this.utils.showNeutralMessage('Deivery Address are restricted to Jamaica');
        // }
        const {geometry: { location: {lat, lng}}} = resp.results[0];
        const position: google.maps.LatLng = new google.maps.LatLng(lat, lng);
        this.addMarker(position);
        this.map.panTo(position);
      }
    );
  }

  dismiss() {
    return this.modalCtrl.dismiss({
      dismissed: true,
      returnedAddress: this.returnedAddress,
      guestDeliveryAddress: this.guestDeliveryAddress,
    });
  }

}
