import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderComponent } from './order/order.component';
import { IonicModule } from '@ionic/angular';
import { MerchantCardComponent } from './merchant-card/merchant-card.component';
import { RouterModule } from '@angular/router';
import { SideGroupComponent } from './side-group/side-group.component';
import { LandingCardComponent } from './landing-card/landing-card.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
// @ts-ignore
import { MerchantCardTwoComponent } from './merchant-card-two/merchant-card-two.component';
import { SuggestedItemComponent } from './suggested-item/suggested-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AddressCardComponent } from './address-card/address-card.component';
import { AppSearchBarComponent } from './app-search-bar/app-search-bar.component';
import { TermsAndPolicyComponent } from './terms-and-policy/terms-and-policy.component';


@NgModule({
  declarations: [
    OrderComponent,
    MerchantCardComponent,
    PageFooterComponent,
    SideGroupComponent,
    LandingCardComponent,
    SuggestedItemComponent,
    MerchantCardTwoComponent,
    AddressCardComponent,
    AppSearchBarComponent,
    TermsAndPolicyComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatStepperModule,
    CommonModule,
    IonicModule,
    RouterModule
],
  exports: [
    OrderComponent,
    MerchantCardComponent,
    PageFooterComponent,
    SideGroupComponent,
    LandingCardComponent,
    SuggestedItemComponent,
    MerchantCardTwoComponent,
    AddressCardComponent,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatFormFieldModule,
    AppSearchBarComponent,
    TermsAndPolicyComponent
  ]
})
export class SharedComponentsModule { }
