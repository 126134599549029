import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeliveryAddress, BillingAddress, User } from 'src/app/models/user.model';
import { AuthService } from './auth.service';
import { Observable, Subject } from 'rxjs';
import { tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/takeout.model';
import { OrderStatusUpdate } from '../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private url = environment.APIEndpoint;
  private _refreshNeeded = new Subject<void>();
  private user: User;
  private deliveryAddressAdded;

  constructor(
    private http: HttpClient, private auth: AuthService
  ) { }

  get refreshNeeded() {
    return this._refreshNeeded;
  }

  setUser(user: User){
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  getDeliveryAddressAdded() {
    return this.deliveryAddressAdded;
  }

  public getAddresses(userId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.url +
      `/users/${userId}/delivery_addresses`, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      }
    });
  }

  public addAddress(userId: number, deliveryAddress: DeliveryAddress): Observable<ApiResponse> {
    const { label, line1, line2, city, parish, country, latitude, longitude, complexName, deliveryInstructions, zip_code} = deliveryAddress;
    this.deliveryAddressAdded = deliveryAddress;
    return this.http.post<ApiResponse>(this.url + `/users/${userId}/delivery_addresses`, {
      label,
      line1,
      line2,
      city,
      parish,
      country,
      latitude,
      longitude,
      complex_name: complexName,
      delivery_instructions: deliveryInstructions,
      zip_code
    }, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      }
    })
    .pipe(
      tap(() => {
        this.refreshNeeded.next();
      })
    );
  }

  public updateAddress(userId: number, addrId: number, deliveryAddress: DeliveryAddress) {
    const { label, line1, line2, city, parish, country, latitude, longitude, complexName, deliveryInstructions, zip_code} = deliveryAddress;
    return this.http.put<ApiResponse>(this.url + `/users/${userId}/delivery_addresses/${addrId}`, {
      label,
      line1,
      line2,
      city,
      parish,
      country,
      latitude,
      longitude,
      complex_name: complexName,
      delivery_instructions: deliveryInstructions,
      zip_code
    }, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      }
    })
    .pipe(
      tap(() => {
        this.refreshNeeded.next();
      })
    );
  }

  cancelOrder(orderStatusUpdate: OrderStatusUpdate) {
    const {userId, orderId, status, name, email} = orderStatusUpdate;
    return this.http.put<ApiResponse>(this.url + `/users/${userId}/orders/${orderId}`, {
      status,
      name,
      email
    }, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      }
    })
    .pipe(
      tap(() => {
        this.refreshNeeded.next();
      })
    );
  }

  public deleteAddress(userId: number, addrId: number) {
    return this.http.delete<ApiResponse>(this.url + `/users/${userId}/delivery_addresses/${addrId}`, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      }
    })
    .pipe(
      tap(() => {
        this.refreshNeeded.next();
      })
    );
  }

  public updateUserInfo(userId: number, updatedInfo: any) {
    const {firstname, lastname, email, phone} = updatedInfo;
    return this.http.patch<ApiResponse>(this.url + `/users/${userId}/update_user`, {
      firstname,
      lastname,
      email,
      phone
    }, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      }
    })
    .pipe(
      tap(() => {
        this.refreshNeeded.next();
      })
    );
  }

  rateTakekoutItem(user_id:number,merchant_id:number,item_id,rating:number) : Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(this.url+`/users/${user_id}/rates`,{
      merchant_id,
      rating,
      item_id
    }).pipe(
      tap(()=>{
        this.refreshNeeded.next()
      })
    )
  }

  getRatingForTakeoutItem(userId:number,merchantId:number,takeoutId:number)
  {
    return this.http.get<ApiResponse>(this.url+`/users/${userId}/rates/${merchantId}/item-${takeoutId}`).pipe(
      tap(()=>{
        this.refreshNeeded.next()
      })
    )
  }
}
