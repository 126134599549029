import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { Item } from 'src/app/models/menu.model';
import { Merchant } from 'src/app/models/merchant.model';
import { CartService } from 'src/app/services/cart.service';
import { CartItem } from 'src/app/models/cart.model';
import { SubSink } from 'subsink';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-suggested-items',
  templateUrl: './suggested-items.page.html',
  styleUrls: ['./suggested-items.page.scss'],
})
export class SuggestedItemsPage implements OnInit, OnDestroy {

  @Input() suggestedItems: any;
  @Input() merchant: Merchant;
  @Input() itemAdded: CartItem;
  private subs = new SubSink();
  total = 0;
  cartQuantity = 0;
  constructor(
    private cartService: CartService,
    private router: Router,
    private alertCtrl: AlertController,
    private modalController: ModalController,
    private util: UtilsService,
  ) { }

  ngOnInit() {
    this.total = this.cartService.getTotal();
    this.cartQuantity = this.cartService.getQuantity();
  }

  async openOrderModal(item: Item) {
    if (this.cartService.merchant === null || this.cartService.merchant.id === this.merchant.id) {
      this.dismiss();
      this.router.navigateByUrl(`merchants/${this.merchant.id}/${item.id}`, {
        state: {item, isOpen: this.merchant.is_open},
      });

    } else {
      const alert = await this.alertCtrl.create({
        header: 'Important!',
        // tslint:disable-next-line: max-line-length
        message: 'You can only order from one merchant at a time. If you wish to order from this merchant, please clear the other items from your cart.',
        buttons: ['Okay']
      });

      alert.present();
    }
  }

  dismiss() {
    this.modalController.dismiss(true);
  }

  async openCart() {
    this.dismiss();
    this.router.navigateByUrl(`cart`);
  }

  backToMerchant() {
    this.dismiss();
    this.router.navigateByUrl(`merchants/${this.merchant.id}`);
  }

  async checkout() {
    this.dismiss();
    this.router.navigateByUrl(`checkout`);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
