import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../models/takeout.model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { tap} from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
import { Merchant } from '../models/merchant.model';

@Injectable({
  providedIn: 'root',
})
export class FeedService {
  private url = environment.APIEndpoint;
  private _favouriteMerchants: Merchant[];
  private favouriteMerchantsBS = new BehaviorSubject<Merchant[]>([]);
  private _refreshNeeded = new Subject<void>();

  constructor(private http: HttpClient, private auth: AuthService) {}


  get refreshNeeded() {
    return this._refreshNeeded;
  }

  updateFavouriteMerchants() {
    this.favouriteMerchantsBS.next(this.favouriteMerchants);
  }

  /**
   * Retrieves all menus for a particular merchant
   * @param merchantId Unique identifier for a merchant
   * @returns An observable of type ApiResponse
   */
  public getTakeOutItems(): Observable<any> {
    return this.http.get<any>(this.url + '/feed/takeout', {
      params: {
        day: this.getDay(),
        time: this.getTime(),
      },
    });
  }

  public getMenus(merchantId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.url + `/merchants/${merchantId}/menus`,
      {
        headers: {
          Authorization: 'bearer ' + this.auth.token,
        },
        params: {
          active: 'true',
          condensed: 'true'
        },
      }
    );
  }

  public getMenu(merchantId: number, menuId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.url + `/merchants/${merchantId}/menus/${menuId}`,
      {
        headers: {
          Authorization: 'bearer ' + this.auth.token,
        }
      }
    );
  }

  public getTakeoutItem(merchantId: number, takeoutId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.url + `/merchants/${merchantId}/takeout/${takeoutId}`,
      {
        headers: {
          Authorization: 'bearer ' + this.auth.token,
        }
      }
    );
  }

  /**
   * Retrieves all merchants
   *
   * @returns An observable of type ApiResponse
   */
  public getMerchants(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.url + '/feed/merchants', {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      },
    });
  }

  /**
   * Retrieves all featured merchants
   *
   * @returns An observable of type ApiResponse
   */
  public getFeaturedMerchants(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.url + '/merchants?featured=true', {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      },
    });
  }

  /**
   * Retrieves all featured merchants
   *
   * @returns An observable of type ApiResponse
   */
   public searchForMerchant(searchTerm): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.url + `/merchants?filter=${searchTerm}`, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      },
    });
  }

  /**
   * Retrieves the informaion of a specific merchant
   * @param merchantId Unique identifier for a merchant
   * @returns An `observable` of type `ApiResponse`
   */
  public getMerchant(merchantId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.url + `/merchants/${merchantId}`, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      },
    });
  }


  /**
   * Sends a request to add a merchant to the logged in user's list of favourites
   * @param merchantId Unique identifier for a merchant
   */
  public favouriteMerchant(merchantId: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.url + `/users/${this.auth.currentUser.id}/favourites/${merchantId}`,
      {
        headers: {
          Authorization: 'bearer ' + this.auth.token,
        },
      }
    )
    .pipe(
      tap(() => {
        this.refreshNeeded.next();
      })
    );
  }

  /**
   * Sends a request to remove a merchant from the logged in user's list of favourites
   * @param merchantId Unique identifier for a merchant
   */
  public unFavouriteMerchant(merchantId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.url + `/users/${this.auth.currentUser.id}/favourites/${merchantId}`,
      {
        headers: {
          Authorization: 'bearer ' + this.auth.token,
        },
      }
    )
    .pipe(
      tap(() => {
        this.refreshNeeded.next();
      })
    );
  }

  /**
   * Sends a request to retrieve all the merchants in a logged in user's list of favourites
   * and stores them in the `_favouriteMerchants` array
   */
  public fetchFavouriteMerchants() {
    this.http
      .get<ApiResponse>(
        this.url + `/users/${this.auth.currentUser.id}/favourites`,
        {
          headers: {
            Authorization: 'bearer ' + this.auth.token,
          },
        }
      )
      .subscribe((resp) => {
        this.favouriteMerchants = resp.data.favourite_merchants as Merchant[];
        this.updateFavouriteMerchants();
      });
  }


  /**
   * Sends a request to become a merchant.
   * @param signUpData Data from the Landing Form.
   */
  public becomeMerchant(signUpData: any) {
    const {name, email, phone, message} = signUpData;
    return this.http.post<ApiResponse>(this.url + `/feed/merchant_signup`, {
      name,
      email,
      phone,
      message
    }, {
      headers: {
        Authorization: 'bearer ' + this.auth.token,
      }
    });
  }

  /**
   * Sets the `_favouriteMerchants` variable
   */
  set favouriteMerchants(favouriteMerchants: Merchant[]) {
    this._favouriteMerchants = favouriteMerchants;
  }

  /**
   * Gets the `_favouriteMerchants` variable
   */

  get favouriteMerchants(): Merchant[] {
    return this._favouriteMerchants;
  }

  /**
   * Returns the list of favourite merchants as an observable that can be
   * subscribed to for updates
   */
  getFavouriteMerchantsObservable(): Observable<Merchant[]> {
    return this.favouriteMerchantsBS.asObservable();
    // return this._favouriteMerchants;
  }

  private getTime(): string {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}`;
  }

  private getDay(): string {
    switch (new Date().getDay()) {
      case 0:
        return 'S';
      case 1:
        return 'M';
      case 2:
        return 'T';
      case 3:
        return 'W';
      case 4:
        return 'R';
      case 5:
        return 'F';
      case 6:
        return 'N';
    }
  }
}
