export interface Takeout {
  category_id: number;
  description: string;
  id: number;
  merchant_id: number;
  name: string;
  photo: string;
  price: number;
  options: [];
}

export interface OrderSideItem {
  id: number;
  quantity: number;
  price: number;
  side_group_name: string;
  selected_side: string;
}

export interface OrderItem {
  notes?: any;
  order_id: number;
  quantity: number;
  price: number;
  takeout: Takeout;
  takeout_id: number;
  open?: boolean;
  size: string;
  sides: OrderSideItem[];
  avg_rating?: number;
  menu_id: number;
}

export class PriceUpdate {
  action: string;
  price: number;

  constructor(action: string, price: number) {
    this.action = action;
    this.price = price;
  }
}

export interface Order {
  contact_email: string;
  contact_name: string;
  contact_number: string;
  delivery_address: string;
  delivery_method: string;
  id: number;
  code: string;
  payment_method?: string;
  payment?: any;
  items: OrderItem[];
  merchant_id: number;
  merchant_name: string;
  placed_at: string;
  status: number;
  user_id: number;
  open?: boolean;
}

export interface OrderDeliveryAddress {
  order_id: number;
  line1?: string;
  line2?: string;
  city?: string;
  parish?: string;
  open?: boolean;
  // deliveryInstructions?: string;
}

export interface OrderStatusUpdate {
  userId: number;
  orderId: number;
  status: number;
  name: string;
  email: string;
}

export class Receipt {
  cardNumber: string;
  uniqueId: string;
  websiteAddress: string;
  purchaserName: string;
  transactionDate: string;
  transactionAmount: string;
  currency: string;
  authorizationCode: string;
  merchantName: string;
  chargedBy: string;
  orderNumber: string;

  constructor(
    cardNumber: string,
    uniqueId: string,
    websiteAddress: string,
    purchaserName: string,
    transactionDate: string,
    transactionAmount: string,
    currency: string,
    authorizationCode: string,
    merchantName: string,
    chargedBy: string,
    orderNumber: string,
    ) {
      this.cardNumber = cardNumber;
      this.uniqueId = uniqueId;
      this.websiteAddress = websiteAddress;
      this.purchaserName = purchaserName;
      this.transactionDate = transactionDate;
      this.transactionAmount = transactionAmount;
      this.currency = currency;
      this.authorizationCode = authorizationCode;
      this.merchantName = merchantName;
      this.chargedBy = chargedBy;
      this.orderNumber = orderNumber;
    }
}
