import { Component, OnInit } from '@angular/core';
import { Platform, NavController, LoadingController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { CartService } from './services/cart.service';
import { SocketService } from './services/socket.service';
import { google } from '@google/maps';
import { LoginFormPage } from './auth/login-form/login-form.page';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private cartService: CartService,
    private navCtrl: NavController,
    private socketService: SocketService,
    private modalCtrl: ModalController,
    private router: Router,
    private storage: Storage,
  ) {
    this.initializeApp();
  }

  ngOnInit() {
  }

  async initializeApp() {
    this.navCtrl.navigateRoot('home');
    this.initCart();
    this.platform.ready().then(() => {
      this.statusBar.styleBlackTranslucent();
      this.splashScreen.hide();
      this.socketService.initializeSocket();
    });
  }

  isGuest(): boolean {
    return this.authService.isGuest;
  }

  async initCart() {
    const cart = JSON.parse(localStorage.getItem('cart'));
    if (!cart) {
      localStorage.setItem('cart', JSON.stringify([]));
    }
  }

  onLogout() {
    this.authService.logout();
    this.cartService.clearCart();
    this.navCtrl.navigateRoot('home');
  }

  async login() {
    const modal = await this.modalCtrl.create({
      component: LoginFormPage,
      cssClass: 'login-modal'
    });
    return await modal.present();
  }
}
