import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.page.html',
  styleUrls: ['./payment.page.scss'],
})
export class PaymentPage implements OnInit {

  @Input() card;
  @Input() action;
  @ViewChild('frame') confirmFrame;
  isLoading = true;
  constructor(private modalController: ModalController) {}

  ngOnInit() {

    if(this.action == "confirm"){
      this.confirmFrame.nativeElement.contentDocument.write(this.card['htmlFormData']);
    }
    else if(this.action =="payment"){
      this.confirmFrame.nativeElement.contentDocument.write(this.card['htmlData']);
    }
    else{
      this.confirmFrame.nativeElement.contentDocument.write("You may be lost. Please go back.");
    }
    this.stopLoading();
  }

  stopLoading() {
    setTimeout(() => {
      this.isLoading = false;
    }, 5000);
  }

  dismiss() {
    return this.modalController.dismiss({
      dismissed: true,
    });
  }
}
