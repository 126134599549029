export interface Item {
  category_id: number;
  description: string;
  id: number;
  merchant_id: number;
  name: string;
  photo: string;
  price: number;
  out_of_stock: boolean;
  is_featured_img: boolean;
  options: SideGroup[];
  qty?: number;
  sizes?: [];
  avg_rating: number;
  menu_id: number;
}

export interface SideGroup {
  id: number;
  title: string;
  max_qty: number;
  sides: Side[];
  is_required: boolean;
  open?: boolean;
}

export interface ChangeTotalEvent {
  id: number;
  total: number;
}

export class SelectedSide {
  id: number;
  qty: number;
  name: string;
  price: number;
  sideGroupTitle: string;

  constructor(id, qty, name, price, sideGroupTitle) {
    this.id = id;
    this.qty = qty;
    this.name = name;
    this.price = price;
    this.sideGroupTitle = sideGroupTitle;
  }

  toString(): string {
    return this.sideGroupTitle + ' ' + this.qty + ' ' + this.name;
  }
}

export interface Category {
  id: number;
  items: Side[];
  menu_id: number;
  title: string;
}

export interface Side {
  price: number;
  takeout: Item;
  qty: number;
}

export interface Menu {
  active_from_time: string;
  active_on_days: string;
  active_to_time: string;
  categories: Category[];
  id: number;
  merchant_id: number;
  title: string;
}

export interface CondensedMenu {
  id: number;
  title: string;
}
