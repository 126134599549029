import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {UserService } from 'src/app/services/user.service';
import { DeliveryAddress, MapAddress} from 'src/app/models/user.model';
import { MapService } from 'src/app/services/map.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SubSink } from 'subsink';
import { GuestUserService } from 'src/app/services/guest-user.service';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.page.html',
  styleUrls: ['./address-modal.page.scss'],
})
// @ts-ignore
export class AddressModalPage implements OnInit, OnDestroy {
  @Input() addressDetails: any;
  addressName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  parish: string;
  country: string;
  businessName: string;
  zipCode: string;
  deliveryInstructions: string;
  private subs = new SubSink();
  mapAddress: MapAddress;
  longAddress: any;
  deliveryAddress: DeliveryAddress;
  userAddresses: DeliveryAddress[];
  guestDeliveryAddress: DeliveryAddress;
  returnedAddress: DeliveryAddress;
  splitAdd = [];
  userId: number;

  constructor(
    private modalCtrl: ModalController,
    private mapService: MapService,
    private userService: UserService,
    private guestUserService: GuestUserService,
    private authService: AuthService,
    private util: UtilsService,
    ) { }

  ngOnInit() {
    this.userId = this.authService.currentUser.id;
    this.mapAddress = this.mapService.getSelectedAddress();
    this.longAddress = this.mapService.getLongAddress();
    if (this.mapAddress !== undefined) {
      this.splitAdd = this.mapAddress.address.split(',');
    }
    this.deliveryAddress = this.mapAddress;

    this.userService.getAddresses(this.authService.currentUser.id).subscribe(
      resp => {
        if (resp.code === 20) {
          this.userAddresses = resp.data as DeliveryAddress[];
        } else {
          console.dir(resp);
          this.util.showSuccessMessage(resp.message[0]);
        }
      },
      error => {
        this.util.showErrorMessage('Could not fetch addresses');
      }
    );

    // Populate the relevant form fields with data from the previous address if available
    if (this.addressDetails) {
      this.addressName = this.addressDetails.label;
      this.businessName = this.addressDetails.complex_name;
      this.deliveryInstructions = this.addressDetails.delivery_instructions;
    }

    // Used to filter the value placed in each field since the length of the address varies
    this.populateFields();
  }

  populateFields() {
    // Special Filter for US related addresses
    if (this.splitAdd[this.splitAdd.length - 1].trim() === 'USA') {
      this.addressLine1 = this.longAddress[0].long_name + ' ' + this.longAddress[1].short_name;
      this.addressLine2 = '';
      this.city = this.splitAdd[1].trim();
      this.parish = this.longAddress[this.longAddress.length - 4].short_name;
      this.country = this.longAddress[this.longAddress.length - 3].long_name;
      this.zipCode = this.longAddress[this.longAddress.length - 2].long_name;
      return;
    }

    if (this.splitAdd.length === 3) {
      this.addressLine1 = this.splitAdd[0];
      this.addressLine2 = '';
      this.city = this.splitAdd[1];
      this.parish = this.longAddress[this.longAddress.length - 2].long_name;
      this.country = this.longAddress[this.longAddress.length - 1].long_name;
      return;
    }

    if (this.splitAdd.length === 4) {
      this.addressLine1 = this.splitAdd[0];
      this.addressLine2 = this.splitAdd[1];
      this.city = this.splitAdd[2];
      this.parish = this.longAddress[this.longAddress.length - 2].long_name;
      this.country = this.longAddress[this.longAddress.length - 1].long_name;
      return;
    }

    if (this.splitAdd.length === 5) {
      this.addressLine1 = this.splitAdd[0] + this.splitAdd[1];
      this.addressLine2 = this.splitAdd[2];
      this.city = this.splitAdd[3];
      this.parish = this.longAddress[this.longAddress.length - 2].long_name;
      this.country = this.longAddress[this.longAddress.length - 1].long_name;
      return;
    }
  }

  addAddress(form: NgForm) {
    const {
      addressName,
      addressLine1,
      addressLine2,
      city,
      parish,
      country,
      businessName,
      deliveryInstructions,
      zipCode
    } = form.value;

    this.deliveryAddress.label = addressName;
    this.deliveryAddress.label = addressName;
    this.deliveryAddress.line1 = addressLine1;
    this.deliveryAddress.line2 = addressLine2;
    this.deliveryAddress.city = city;
    this.deliveryAddress.parish = parish;
    this.deliveryAddress.country = country;
    this.deliveryAddress.complexName = businessName;
    this.deliveryAddress.deliveryInstructions = deliveryInstructions;
    this.deliveryAddress.zip_code = zipCode;

    const newAddress = this.deliveryAddress;
    this.userAddresses.push(newAddress);
    if (!this.authService.isGuest) {
      this.returnedAddress = newAddress;
      this.subs.sink = this.userService.addAddress(this.userId, newAddress).subscribe(
        resp => {
          if (resp.code !== 20) {
            this.util.showSuccessMessage(resp.message[0]);
            this.userAddresses = this.userAddresses.filter(a => a.id !== null);
          }
        },
        error => {
          this.userAddresses = this.userAddresses.filter(a => a.id !== null);
          this.util.showErrorMessage('Could not add address');
        }
      );
    } else {
      this.guestDeliveryAddress = newAddress;
      this.guestUserService.setDeliveryAddress(newAddress);
    }

    this.dismiss();
  }

  updateAddress(form: NgForm) {
    const {
      addressName,
      addressLine1,
      addressLine2,
      city,
      parish,
      country,
      businessName,
      deliveryInstructions,
      zipCode
    } = form.value;

    const updatedAddress: DeliveryAddress = {
      label: addressName,
      line1: addressLine1,
      line2: addressLine2,
      city,
      parish,
      country,
      complexName: businessName,
      deliveryInstructions,
      latitude: this.addressDetails.latitude,
      longitude: this.addressDetails.longitude,
      zip_code: zipCode,
    };

    this.subs.sink = this.userService.updateAddress(this.userId, this.addressDetails.id, updatedAddress).subscribe(
      resp => {
        if (resp.code === 20) {
          this.util.showSuccessMessage(resp.message[0]);
          this.dismiss();
        }
      },
      error => {
        this.util.showErrorMessage('Could not Update address');
      }
    );
  }

  dismiss() {
    return this.modalCtrl.dismiss({
      dismissed: true,
      guestDeliveryAddress: this.guestDeliveryAddress,
      returnedAddress: this.returnedAddress
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
