import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { User, UserResponse } from '../models/user.model';
import { ApiResponse } from '../models/takeout.model';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import decode from 'jwt-decode';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap} from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { UtilsService } from './utils.service';
import { SignUpPage } from '../sign-up/sign-up.page';
import { UserService } from './user.service';
import { VerificationModalPage } from '../components/verification-modal/verification-modal.page';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url = environment.APIEndpoint;
  // tslint:disable-next-line: variable-name
  authState = new BehaviorSubject(false);
  // tslint:disable-next-line: variable-name
  private _currentUser: User;
  token: string;
  // tslint:disable-next-line: variable-name
  private _isGuest = false;
  private respUserId: number;
  signUpPage: SignUpPage;
  userId: number;
  respId: number;

  constructor(
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private router: Router,
    private storage: Storage,
    private utils: UtilsService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
  ) {
    this.isLoggedIn();
  }

  /**
   * Checks to see if a users has a token saved in storage, and logs them in if
   * one is found
   */
  async isLoggedIn() {
    const resp = await this.storage.get('user');
    if (resp) {
      this.authState.next(true);
      this._currentUser = resp.user;
      this.token = resp.token;
    } else {
      await this.loginGuest();
    }
  }

  get isGuest(): boolean {
    return this._isGuest;
  }
  /**
   * Checks if a user is logged in
   * @returns the app's auth state
   */
  isAuthenticated(): boolean {
    return this.authState.value;
  }

  /**
   * Logs in a user as a guest and navigates them to the home screen
   */
  async loginGuest() {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
      translucent: true,
    });

    loading.present();

    this.signUp(
      'Guest',
      'User',
      '8760000000',
      'guestuser@example.com',
      '0000',
      '0000'
    ).subscribe(
      resp => {
        if (resp.code === 21 || resp.code === 42) {
          // sign in
          loading.dismiss();
          this._isGuest = true;
          this.login('8760000000', '0000');
        }
        loading.dismiss();
      },
      err => {
        loading.dismiss();
        this.utils.showServerError();
      }
    );
  }

  /**
   * Attempts to log in a user with their login credentials
   * @param phone the user's phone number
   * @param password the user's password
   */
  async login(phone: string, password: string) {
    phone = phone.trim();
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
      translucent: true,
    });

    loading.present();
    this.http
    .post<ApiResponse>(this.url + '/users/auth/login', {
      phone,
      password,
    })
    .subscribe(
      (resp: ApiResponse) => {
        if (resp.type === 'success') {
          this.token = resp.data.token;
          const payload = decode(this.token);

          const { permissions, orders, trn, merchant_id, ...user } = resp.data
            .user as UserResponse;
          user.id = payload.id;
          this.userId = user.id;
          this._currentUser = user;
          if (user.firstname !== 'Guest') {
            this.storage.set('user', { user, token: this.token });
            this._isGuest = false;
          } else {
            this._isGuest = true;
          }
          this.authState.next(true);
          loading.dismiss();

        } else {
          if (resp.code === 42 ) {
            loading.dismiss();
            this.utils.showErrorMessage(resp.message[0]);
            this.respId = resp.data.user_id;
            this.promptVerification();
          } else {
            loading.dismiss();
            this.utils.showErrorMessage(resp.message[0]);
          }
        }
      },
      err => {
        loading.dismiss();
        this.utils.showServerError();
      }
    );
  }

  public verifyRecaptcha(token: string): Promise<ApiResponse> {
    return this.http.post<ApiResponse>(this.url + `/users/auth/verify_recaptcha`, {
      token
    }).toPromise();
  }

  /**
   * Logs out any user currently logged in
   */
  async logout() {
    this.authState.next(false);
    this._currentUser = null;
    this._isGuest = true;
    this.token = null;
    this.storage.clear();

    await this.loginGuest();
  }

  getUserId() {
    return this.userId;
  }

  /**
   * @returns Returns the user object of the currently logged in user
   */
  get currentUser(): User {
    return this._currentUser;
  }

  updateCurrentUserInfo(user: User) {
    this._currentUser = user;
  }

  /**
   * Sends a request to signUp a user.
   */
  signUp(
    firstname: string,
    lastname: string,
    phone: string,
    email: string,
    password: string,
    confirm_password: string
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.url + '/users', {
      firstname,
      lastname,
      phone,
      email,
      password,
      confirm_password,
    });
  }

  changePassword(
    old_password: string,
    new_password: string,
    confirm_password: string
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.url + `/users/${this._currentUser.id}/change_password`,
      { old_password, new_password, confirm_password }
    );
  }

  /**
   * Sends the request to reset a user's password.
   * @param email the email to send the reset link to
   */
  async resetPassword(email: string) {
    const loading = await this.loadingCtrl.create({
      message: 'Preparing email...',
      translucent: true,
    });
    loading.present();

    this.http
    .post<ApiResponse>(this.url + '/users/forgot_password', { email })
    .subscribe(
      (resp: ApiResponse) => {
        loading.dismiss();
        this.utils.showSuccessMessage(resp.message[0]);
      },
      err => {
        console.log(err);
        loading.dismiss();
        this.promptVerification();
        this.utils.showErrorMessage(
          'We cannot process your request',
          'Server Down',
          5000
        );
      }
    );
  }

  /**
   * Set the user id to the id received from the sign up response
   * @param id;
   */
  setRespUserId(id: number) {
    this.respUserId = id;
  }

  /**
   * Gets the user id received from the sign up response
   */

  getRespUserId() {
    return this.respUserId;
  }

  /**
   * Send a request to verify the user's email address.
   * @param userId;
   * @param verification_code;
   */
  async verifyEmail(userId: number, verification_code) {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
      translucent: true,
    });

    loading.present();
    this.http.post<ApiResponse>(this.url + `/users/${userId}/verify_email`, { verification_code })
    .subscribe(
      (resp: ApiResponse) => {
        loading.dismiss();
        this.utils.showSuccessMessage(resp.message[0]);
      },
      err => {
        console.log(err);
        loading.dismiss();
        this.utils.showErrorMessage(
          'We cannot process your request',
          'Server Down',
          5000
        );
      }
    );
  }

  /**
   * Send a request to request a new verification code.
   * @param userId;
   */
  async requestNewCode(userId: number) {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
      translucent: true,
    });

    loading.present();
    this.http.get<ApiResponse>(this.url + `/users/${userId}/verify_email`)
    .subscribe(
      (resp: ApiResponse) => {
        loading.dismiss();
        this.utils.showSuccessMessage(resp.message[0]);
      },
      err => {
        console.log(err);
        loading.dismiss();
        this.utils.showErrorMessage(
          'We cannot process your request',
          'Server Down',
          5000
        );
      }
    );
  }

  async promptVerification() {
    const modal = await this.modalCtrl.create({
      component: VerificationModalPage,
      cssClass: 'verification-modal',
      backdropDismiss: false,
      componentProps: {
        requestNewCode: (userId) => { this.requestNewCode(userId); },
        verifyEmail: (userId, code) => { this.verifyEmail(userId, code); },
        userId: this.respId,
      }
    });

    return modal.present();

    // DEPRECATED
    // const alert = await this.alertCtrl.create({
    //   header: 'Please Verify Email',
    //   subHeader: 'Check your email for code.',
    //   message: 'Please enter the code received to verify your email address.',
    //   inputs: [
    //     {
    //       name: 'code',
    //       type: 'text',
    //       placeholder: '000000'
    //     }
    //   ],
    //   buttons: [
    //     {
    //       text: 'Submit',
    //       handler: ({code}) => {
    //         this.verifyEmail(this.respId, code);
    //       }
    //     },
    //     {
    //       text: 'Cancel',
    //       role: 'cancel',
    //     },
    //     {
    //       text: 'Request new code',
    //       handler: () => {
    //         this.requestNewCode(this.respId);
    //         this.promptVerification();
    //       }
    //     },
    //   ]
    // });

    // await alert.present();
  }
}
