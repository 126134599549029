import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-policy-page',
  templateUrl: './policy-page.page.html',
  styleUrls: ['./policy-page.page.scss'],
})
export class PolicyPagePage implements OnInit {

  constructor(
    private modalController: ModalController,
  ) { }

  @Input() type: string;

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
